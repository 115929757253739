import React from 'react';
import loadable from '@loadable/component';
import Fallback from '../../Components/Fallback';
import MetaComponent from '../../Components/MetaComponent';

const Vision = loadable(
  () => import(/* webpackChunkName: "Vision" */ './Vision'),
  {
    fallback: <Fallback />
  }
);
const Mission = loadable(
  () => import(/* webpackChunkName: "Mission" */ './Mission'),
  {
    fallback: <Fallback />
  }
);
const Brands = loadable(
  () => import(/* webpackChunkName: "Brands" */ './Brands'),
  {
    fallback: <Fallback />
  }
);
const Education = loadable(
  () => import(/* webpackChunkName: "Education" */ './Education'),
  {
    fallback: <Fallback />
  }
);
const Sociana = loadable(
  () => import(/* webpackChunkName: "Sociana" */ './Sociana'),
  {
    fallback: <Fallback />
  }
);
const About = loadable(
  () => import(/* webpackChunkName: "About" */ './About'),
  {
    fallback: <Fallback />
  }
);
const Superbrain = loadable(
  () => import(/* webpackChunkName: "Superbrain" */ './Superbrain'),
  {
    fallback: <Fallback />
  }
);
const JoinUs = loadable(
  () => import(/* webpackChunkName: "JoinUs" */ './JoinUs'),
  {
    fallback: <Fallback />
  }
);
const CoolProducts = loadable(
  () => import(/* webpackChunkName: "CoolProducts" */ './CoolProducts'),
  {
    fallback: <Fallback />
  }
);

export default function Home() {
  return (
    <>
      <MetaComponent
        url="https://alienbrains.in/"
        title="Alien Brains"
        description="We are both a product and service based company.We are a young brand with a notion for perfection."
      />
      <Vision />
      <Mission />
      <Brands />
      <Education />
      <Sociana />
      <Superbrain />
      <About />
      <JoinUs />
      <CoolProducts />
    </>
  );
}
